import React from 'react'
import { Container } from 'react-bootstrap'

function Ourserv() {
  return (
    <div className="our-main">
      <Container>
        <div className="upper-about borderr">
          <div className="pg innerrr">
            <p> 03 // MY SERVICES </p>

          </div>
          <div className="detail innerrr">
            <p>Our work builds more loyal audiences by combining an unwavering
              focus on their needs and desires, and our relentless pursuit of design excellence.</p>

          </div>
          <div className="ani innerrr">

            <p>(( SERVICE ALL ))</p>

          </div>

        </div>
      </Container>


    </div>
  )
}

export default Ourserv